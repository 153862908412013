import {combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice';
import masterReducer from './masterDataSlice';
import listingReducer from './listingSlice';
import claimReducer from './claimSlice';
import generalReducer from './generalSlice';
import salesRepReducer from './salesRepSlice';

const allReducers = combineReducers({
    user: userReducer,
    master: masterReducer,
    listing: listingReducer,
    claim: claimReducer,
    general: generalReducer,
    salesrep: salesRepReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
      }
      
      return allReducers(state, action);
}

const appStore = configureStore({
    reducer: rootReducer
});

export default appStore;