import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withTokenPostAPI } from '../utils/service';
import { errorToast, successToast } from '../utils/constants';

const PasswordUpdateForm = ({ userId, onClose }) => {

  const [serverErrors, setServerErrors] = useState([]);
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Old password is required'),
    new_password: Yup.string().required('New password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
      .required('Please confirm your new password'),
  });

  const handleSubmit = async(values, { setSubmitting }) => {
        
        const url = "/api/updatepassword";
        const postForm = await withTokenPostAPI(url, values);

        if (postForm.code === 200) {
        setTimeout(() => {
            successToast(postForm.message);
        }, 1);
        setSubmitting(false);
        onClose();
        } else {
            // errorToast(postForm.message);
            if(postForm.error) {
                setServerErrors(postForm.error);
            }
        }
        
  };

  

  return (
    <div className="modal d-block updatePasswordModal" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Password</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
          <Formik
              initialValues={{ id: userId, old_password: '', new_password: '', confirm_password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className='update-pass-form'>
                  <input type='hidden' name="id" value={userId} />
                  <div className="form-group mb-3">
                    <label htmlFor="old_password">Old Password</label>
                    <Field
                      type="password"
                      name="old_password"
                      className="form-control"
                      placeholder="Enter old password"
                      autoComplete="off"
                    />
                    <ErrorMessage name="old_password" component="div" className="text-danger" />
                    {serverErrors.old_password ? (
                        <p className="text-danger">{serverErrors.old_password}</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="new_password">New Password</label>
                    <Field
                      type="password"
                      name="new_password"
                      className="form-control"
                      placeholder="Enter new password"
                      autoComplete="off"
                    />
                    <ErrorMessage name="new_password" component="div" className="text-danger" />
                    {serverErrors.new_password ? (
                        <p className="text-danger">{serverErrors.new_password}</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="confirm_password">Re-enter New Password</label>
                    <Field
                      type="password"
                      name="confirm_password"
                      className="form-control"
                      placeholder="Re-enter new password"
                      autoComplete="off"
                    />
                    <ErrorMessage name="confirm_password" component="div" className="text-danger" />
                    {serverErrors.confirm_password ? (
                        <p className="text-danger">{serverErrors.confirm_password}</p>
                    ) : null}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                      Cancel
                    </button>
                    <button type="submit" className="btn updatePasswordBtn" disabled={isSubmitting}>
                      Update Password
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdateForm;